module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-f2b41116e3/2/.yarn/berry/cache/gatsby-plugin-manifest-npm-5.13.0-d134c631ce-10c0.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"53aa06cf17e4239d0dba6ffd09854e02"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-omni-font-loader-virtual-ba5cc6a8c4/2/.yarn/berry/cache/gatsby-omni-font-loader-npm-2.0.2-f5ca2a2c0a-10c0.zip/node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Inter","file":"https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap"}]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-0be4b03311/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
